.Footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: smaller;
  background-color: #f4f4f4;
  height: 160px;
  color: black;
}

@media(max-width:850px) {
  .Footer {
    bottom: 12px;
  }

  .footer-wrapper {
    height: 80px;
  }
}