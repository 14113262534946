.home-content-wrapper {
  padding: 8% 0;
}

.name-line {
  font-size: 27px;
}

.name {
  font-size: 75px;
  font-weight: 600;
}

.i-am-a {
  font-size: 40px;
  margin: 5px 0;
}

.designation {
  font-weight: 500;
  color: crimson;
}

.home-img-wrapper {
  position: absolute;
  right: 6%;
  display: flex;
}

.home-img {
  height: 400px;
  align-self: center;
}

@media(max-width:850px) {
  .home-img-wrapper {
    position: unset;
    height: unset;
    justify-content: center;
  }

  .home-img {
    height: 247px;
  }

  .home {
    flex-direction: column-reverse;
    justify-content: center;
    height: 100%;
  }

  .home-content-wrapper {
    margin: 0;
    padding: 0;
  }

  .name-line {
    font-size: 20px;
  }

  .name {
    font-size: 50px;
  }

  .i-am-a {
    font-size: 25px;
  }
}