.skills-img-wrapper {
    position: absolute;
    right: 2%;
    bottom: 22%;
    display: flex;
}

.skills-img {
    height: 400px;
    align-self: center;
}

.skills-content .column {
    width: 30%;
}

.skills-content .left {
    text-align: left;
}

.skills-content .skill {
    padding-bottom: 15px;
}

.skills-content .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.skills-content span {
    font-weight: 500;
    font-size: 13px;
    line-height: 0;
}

.skills-content .text {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 20px;
}

.MuiSlider-track {
    transition: width 0.5s !important;
  }

@media(max-width: 850px) {
    .skills-content .column {
        width: 90%;
        margin: 0 auto;
    }

    .skills-content .text {
        text-align: center;
    }

    .skills-img-wrapper {
        display: none;
    }
}