.contact-content .text {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.contact-content .left {
    text-align: left;
    width: 40%;
}

.contact-content .left p {
    margin-bottom: 10px;
}

.contact-content .row {
    display: flex;
    height: 65px;
    align-items: center;
}

.contact-content .row i {
    font-size: 25px;
    color: crimson;
}

.contact-content .row .info {
    margin-left: 30px;
}

.contact-content .info .head {
    font-weight: 500;
}

.text-input {
    width: 48.5%;
}

@media(max-width: 850px) {
    .contact-content .left {
        width: 100%;
    }

    .text-input {
        width: 100%;
    }

    .text-input-wrapper {
        flex-direction: column;
    }
}