.about-content>.left>img {
    height: 310px;
    border-radius: 10%;
}

.about-content .right .text {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
}

.about-content .right p {
    text-align: justify;
    font-size: large;
}

@media(max-width: 850px) {
    .about-content .right .text {
        display: flex;
        flex-direction: column;
    }

    .about-content>.left>img {
        height: 190px;
    }
}