.Header {
  margin: 0 6%;
  padding-top: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  overflow: auto;
  max-width: 90%;
  background-color: #f4f4f4;
}

.logo {
  color: #56565e;
  font-size: 35px;
  font-weight: 600;
}

.logo>span {
  color: crimson;
  transition: all .3s ease;
}

.menu {
  display: flex;
  align-items: center;
}

.menu-items {
  padding-left: 25px;
}

.menu-items>a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  transition: color .3s ease;
}

.menu-items>a:hover {
  color: crimson;
}

@media(max-width:850px) {
  .item {
    display: none;
  }
}